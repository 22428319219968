<template>
  <div class="py-6 px-6 h-full">
    <page-title
      :title="lables.title"
      class="pb-6"
      :description="lables.subtitle"
    >
      <template #action>
        <div class="flex space-x-2">
          <search-input
            v-model="search"
            :no-margin="!isEmployeesEmpty"
            :disabled="usersData.loading"
            data-cy="employee-search-button"
          />
          <access-control :roles="[SUPER_ADMIN, ADMIN, CLIENT_ADMIN]">
            <primary-button class="h-10" @click.prevent="showUserModal = true">
              {{ lables.buttonText }}
            </primary-button>
          </access-control>
        </div>
      </template>
    </page-title>

    <div class="space-y-6">
      <lf-scrollable-menu>
        <lf-tab
          v-for="role in roles"
          :key="role"
          :active="activeRole === role"
          @click="selectTab(role)"
        >
          {{ ROLES_DISPLAY_NAMES_MAP[role] }}
        </lf-tab>
      </lf-scrollable-menu>
      <lf-table
        class="h-80-screen 2xl:h-85-screen"
        :columns="columns"
        :data="usersData.data"
        :metadata="usersData.meta"
        :is-table-loading="usersData.loading"
        scrollable-element-id="appWrapper"
        :pagination-absolute="false"
        header-sticky
        @change-page="changePage"
        @change-items-per-page="changePerPage"
      />
    </div>
  </div>
  <user-modal
    v-if="showUserModal"
    :employees="userType === UserType.lendflow"
    :close="() => (showUserModal = false)"
  />
</template>

<script setup lang="ts">
import { computed, watch, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useUsersStore } from "@/stores/users";
import { useRoute } from "vue-router";
import {
  ADMIN,
  ANALYST,
  CLIENT_ADMIN,
  CLIENT_ANALYST,
  CLIENT_FUNDING_ADVISOR,
  CLIENT_ROLE_GROUP,
  CLIENT_UNDERWRITER,
  FUNDING_ADVISOR,
  LENDFLOW_ROLE_GROUP,
  SUPER_ADMIN,
  UNDERWRITER,
  FUNDER_ADMIN,
  FUNDER_USER,
  USER
} from "@/helpers/constants";
import { getParamsWithPagination } from "@/helpers/pagination";
import { getUserRoles } from "@/helpers/formatting";
import { UserType } from "@/enums/auth";
import { useAuth } from "@/hooks/auth";
import { filterArrayFromNullish } from "@/helpers/common";
import { storeToRefs } from "pinia";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import router from "@/router";

import AccessControl from "@/components/AccessControl.vue";
import BadgeList from "@/components/ui/BadgeList.vue";
import LfScrollableMenu from "@/components/ui/LfScrollableMenu.vue";
import LfTab from "@/components/ui/LfTab.vue";
import LfTable from "@/components/ui/table/LfTable.vue";
import PageTitle from "@/components/ui/PageTitle.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import UserMenuButton from "@/views/profile/components/UserMenuButton.vue";
import UserModal from "@/views/profile/modals/UserModal.vue";

import type { LocationQueryRaw } from "vue-router";
import type { Role } from "@/models/options";
import type { PermissionsPreset } from "@/models/permissionsPreset";
import type { IWorkflow } from "@/models/workflows";
import type { IClient } from "@/models/clients";
import type { IDealsColumn } from "@/models/applications";

const props = defineProps<{ userType: UserType }>();

type RoleOrAll = Role | "";

const search = ref("");
const showUserModal = ref(false);
const activeRole = ref("");

const { t } = useI18n();
const { params, query } = useRoute();
const { getters } = useStore();
const auth = useAuth();
const usersStore = useUsersStore();

const ROLES_DISPLAY_NAMES_MAP = {
  [SUPER_ADMIN]: t("COMMON.SUPER_ADMIN"),
  [ADMIN]: t("COMMON.ADMIN"),
  [UNDERWRITER]: t("COMMON.PROCESSOR"),
  [FUNDING_ADVISOR]: t("COMMON.ONBOARDER"),
  [CLIENT_ADMIN]: t("COMMON.ADMIN"),
  [CLIENT_UNDERWRITER]: t("COMMON.PROCESSOR"),
  [USER]: t("COMMON.VIEWER"),
  [ANALYST]: t("COMMON.ANALYST"),
  [CLIENT_ANALYST]: t("COMMON.ANALYST"),
  [CLIENT_FUNDING_ADVISOR]: t("COMMON.ONBOARDER"),
  [FUNDER_ADMIN]: t("COMMON.FUNDER_ADMIN"),
  [FUNDER_USER]: t("COMMON.FUNDER_VIEWER"),
  "": t("COMMON.ALL")
} as Record<RoleOrAll, string>;

const isLoading = ref(false);
const page = ref(Number(query.page) || 1);
const per_page = ref(Number(query.per_page) || 100);
const { usersData } = storeToRefs(usersStore);

const authClientSettings = computed<IClient>(
  () => getters["auth/authClientSettings"]
);
const activeClient = computed<IClient | null>(() => getters["clients/active"]);

const clientId = computed(() =>
  auth.isClient ? authClientSettings.value.id : (params.id as string)
);

const employeesByRole = computed(() =>
  groupBy(usersData.value.data, (user) =>
    getUserRoles(user.roles).sort().join(" - ")
  )
);

const isEmployeesEmpty = computed(() => isEmpty(employeesByRole.value));

const roles = computed<RoleOrAll[]>(() =>
  props.userType === UserType.lendflow
    ? ["", ...LENDFLOW_ROLE_GROUP]
    : ["", ...CLIENT_ROLE_GROUP]
);

const lables = computed(() => {
  if (props.userType === UserType.lendflow) {
    return {
      title: t("EMPLOYEES.TITLE"),
      subtitle: t("EMPLOYEES.SUBTITLE"),
      buttonText: t("EMPLOYEES.ADD_BTN")
    };
  }
  const orgTitle = activeClient.value?.name
    ? `${t("ORGANIZATION.TITLE")} (${activeClient.value?.name})`
    : t("ORGANIZATION.TITLE");
  return {
    title: orgTitle,
    subtitle: t("ORGANIZATION.SUBTITLE"),
    buttonText: t("ORGANIZATION.ADD_USER")
  };
});

const getEmployees = async () => {
  isLoading.value = true;
  const data = {
    clientId: clientId.value,
    params: getParamsWithPagination(
      { search: search.value || null, role: activeRole.value || null },
      page.value,
      per_page.value
    )
  };
  await router.push({ query: data.params as LocationQueryRaw });
  props.userType === UserType.lendflow
    ? await usersStore.getEmployees(data)
    : await usersStore.getUsers(data);
  isLoading.value = false;
};

const changePage = (newPage: number) => {
  page.value = newPage;
  getEmployees();
};

const changePerPage = (newPerPage: number, maxPage: number | null) => {
  if (maxPage) {
    page.value = maxPage;
  }
  per_page.value = newPerPage;
  getEmployees();
};

watch(
  () => search.value,
  () => getEmployees()
);

onMounted(async () => {
  await getEmployees();
});

const columns = computed<IDealsColumn[]>(() => {
  const tableColumns = [
    {
      label: t("COMMON.USER"),
      key: "name",
      component: "user-profile",
      props: { displayNameBadge: true }
    },
    {
      label: t("COMMON.EMAIL"),
      key: "email"
    },
    activeRole.value
      ? null
      : {
          key: "roles",
          label: t("COMMON.ROLES"),
          component: BadgeList,
          props: {
            showNumOfItems: 3,
            rounded: true,
            options: ROLES_DISPLAY_NAMES_MAP
          }
        },
    {
      label: t("COMMON.WORKFLOWS"),
      key: "permissions_preset.assigned_workflow_templates",
      component: BadgeList,
      props: {
        showNumOfItems: 3,
        rounded: true
      },
      formatter: (workflows: IWorkflow[]) =>
        workflows.map((workflow) => workflow.name)
    },
    {
      label: t("COMMON.PRESET"),
      key: "permissions_preset",
      component: BadgeList,
      props: {
        showNumOfItems: 1,
        rounded: true
      },
      formatter: (preset: PermissionsPreset) => {
        return preset.is_published ? [preset.name] : [];
      }
    },
    props.userType === UserType.lendflow
      ? {
          key: "permissions_preset.assigned_clients",
          label: t("COMMON.CLIENTS"),
          component: BadgeList,
          props: {
            showNumOfItems: 3,
            rounded: true
          },
          formatter: (clients: IClient[]) =>
            clients.map((client) => client.name)
        }
      : null,
    {
      label: "",
      key: "",
      component: UserMenuButton,
      props: {
        menuType: props.userType === UserType.lendflow ? "employees" : "clients"
      }
    }
  ];

  return filterArrayFromNullish(tableColumns);
});

const selectTab = (role: RoleOrAll) => {
  activeRole.value = role;
  search.value = "";
  getEmployees();
};

onUnmounted(() => usersStore.unsetUsers());
</script>
